<template>
  <div>
    <a-card :bordered="false" style="width: 100%">
      <div style="text-align: center; font-size: 30px; font-weight: bold">
        {{ title }}
      </div>
      <div v-html="text" id="data"></div>
    </a-card>
    <wang-editor
      v-model="curContent"
      ref="wangEditor"
      @on-change="exec"
      id="wangd_editor"
    />
    <a-back-top />
  </div>
</template>

<script>
import { getVulWiki } from "@/api/wiki";
import Prism from "prismjs";
import WangEditor from "@/components/editor/WangEditor";
export default {
  components: {
    WangEditor,
  },
  data() {
    return {
      text: "",
      wiki_id: "",
      title: "",
      content: "",
      style: "",
      curContent: [],
    };
  },
  methods: {
    parser() {
      var root = document.getElementById("data");
      var data = root.attributes[0].name;
      if (root.children.length >= 1) {
        this.parse(root.children, data);
        // 为 pre 和 code标签 添加line-numbers class
        let pres = document.getElementsByTagName("pre");
        for (let i = 0; i < pres.length; i++) {
          let pre_class = pres.item(i).getAttribute("class");
          if (pre_class !== null) {
            pres.item(i).setAttribute("class", pre_class + " line-numbers");
          } else {
            pres.item(i).setAttribute("class", "line-numbers");
          }
        }
        // 删掉br标签上误添加的data
        let brs = document.getElementsByTagName("br");
        for (let j = 0; j < brs.length; j++) {
          brs.item(j).removeAttribute(data);
        }
        let codes = document.getElementsByTagName("code");
        for (let k = 0; k < codes.length; k++) {
          // code 中如果没有class, 说明是plain_text, 添加text_code class
          let code_class = codes.item(k).getAttribute("class");
          if (code_class !== null) {
            let inner_html = codes.item(k).innerHTML;
            let inser_html = inner_html.replace(/<br>/g, "\n");
            codes.item(k).innerHTML = inser_html;
          } else {
            codes.item(k).setAttribute("class", "text_code");
          }
        }
        Prism.highlightAll();
      } else {
        setTimeout(this.parser, 50);
      }
    },
    parse(root, data) {
      for (let i = 0; i < root.length; i++) {
        root[i].setAttribute(data, "");
        if (root[i].children.length >= 1) {
          this.parse(root[i].children, data);
        }
      }
    },
    exec(text) {
      this.text = text;
      // 隐藏编辑栏
      let editor = document.getElementById("wangd_editor");
      editor.setAttribute("style", "display: none");
      setTimeout(this.parser, 50);
    },
  },
  mounted() {
    this.wiki_id = this.$route.query.wiki_id;
    getVulWiki(this.wiki_id)
      .then((res) => {
        this.title = res.data.title;
        this.content = JSON.parse(res.data.content);
        const content = this.content;
        this.$refs["wangEditor"].init(content);
      })
      .catch((err) => {
        this.$message.error(err.toString());
      });
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
}
table th,
table td {
  border: 1px solid #ccc;
  min-width: 50px;
  height: 20px;
  text-align: left;
}
table th {
  background-color: #f1f1f1;
  text-align: center;
}

/* 代码块 */
.text_code {
  display: block;
  border: 1px solid hsl(0, 0%, 91%);
  border-radius: 4px 4px;
  text-indent: 0;
  background-color: #fafafa;
  padding: 10px;
  font-size: 14px;
}
/* pre > code {
  display: block;
  border: 1px solid hsl(0, 0%, 91%);
  border-radius: 4px 4px;
  text-indent: 0;
  background-color: #fafafa;
  padding: 10px;
  font-size: 14px;
} */

/* 引用 */
blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 10px 10px;
  margin: 10px 0;
  background-color: #f1f1f1;
}

/* 列表 */
ul,
ol {
  margin: 10px 0 10px 20px;
}

/* 分割线 */
hr {
  display: block;
  width: 90%;
  margin: 20px auto;
  border: 0;
  height: 1px;
  background-color: #ccc;
}
</style>

<template>
  <div>
    <a-modal
      :visible="visible"
      title="修改标题"
      :width="700"
      @cancel="handleCancel"
      @ok="submit"
      :confirmLoading="confirmLoading"
    >
      <a-form-model
        :model="form"
        ref="ruleForm"
        layout="horizontal"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="标题" ref="title" prop="title">
          <a-input v-model="form.title" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { updateVulCatalog } from "@/api/wiki";
export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {
        title: "",
      },
      rules: {
        title: [{ required: true, message: "标题不能为空", trigger: "blur" }],
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      id: 0,
    };
  },
  methods: {
    handleCancel() {
      this.$refs["ruleForm"].resetFields();
      this.visible = false;
    },
    init(id, title) {
      this.visible = true;
      this.id = id;
      this.form.title = title;
    },
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          updateVulCatalog(this.id, this.form.title)
            .then((res) => {
              if (res.data.message !== undefined) {
                this.$message.error(res.data.message);
              } else {
                this.$message.success("修改成功!");
                this.$parent.$parent.fetch({
                  page: this.$parent.$parent.current,
                  page_size: this.$parent.$parent.pageSize,
                });
                this.$forceUpdate();
                this.$refs["ruleForm"].resetFields();
                this.visible = false;
              }
            })
            .catch((err) => {
              this.$message.error(err.toString());
            });
          this.confirmLoading = false;
        }
      });
    },
  },
};
</script>

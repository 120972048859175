<template>
  <div style="border-bottom: 1px solid #ccc">
    <!-- 工具栏 -->
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editorId="editorId"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <!-- 编辑器 -->
    <Editor
      style="height: 500px"
      :editorId="editorId"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onChange="onChange"
      @onCreated="onCreated"
    />
  </div>
</template>

<script>
import "@wangeditor/editor/dist/css/style.css";
import store from "@/store";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { oneOf, getUuid } from "@/libs/tools";
import { deleteFile } from "@/api/wiki";
export default {
  props: {
    value: {
      type: Array,
      default: function () {
        return [
          {
            type: "paragraph",
            children: [{ text: "" }],
          },
        ];
      },
    },
    /**
     * @description 模式, 分为简洁和默认
     */
    mode: {
      type: String,
      default: "default",
      validator: (val) => {
        return oneOf(val, ["default", "simple"]);
      },
    },
    /**
     * @description 工具栏配置, 可以设置toolbarKeys以及excludeKeys
     */
    toolbarConfig: {
      type: Object,
      default: function () {
        return {};
      },
    },
    /**
     * @description 编辑器配置
     */
    editorConfig: {
      type: Object,
      default: function () {
        return {
          placeholder: "请输入内容...",
          readOnly: false,
          autoFocus: false,
          scroll: true,
          MENU_CONF: {
            uploadImage: {
              // 服务端地址
              server:
                process.env.NODE_ENV === "development"
                  ? "http://dev-www.lcttty.top:8000/api/daily_life/wang_upload"
                  : "https://www.lcttty.top/api/daily_life/wang_upload",
              // form-data fieldName, 默认值为 'wangeditor-uploaded-file'
              fieldName: "wangeditor-uploaded-file",
              // 单个文件的最大体积, 默认为2M
              maxFileSize: 10 * 1024 * 1024,
              // 最多可上传几个文件, 默认为100
              maxNumberOfFiles: 10,
              // 选择文件时的类型限制, 默认为[image/*], 如不想限制, 择设置为 []
              allowedFileTypes: ["image/*"],
              // 自定义上传参数, 例如传递验证的token等。参数会添加formData中, 一起上传到服务器
              // meta: {
              // token: 'xxx',
              // otherKey: 'yyy'
              // },
              // 将meta拼接到url参数中, 默认为false
              metaWithUrl: false,
              // 自定义增加http header
              // config.headers.Authorization = "Token " + store.state.user.token;
              headers: {
                Authorization: "Token " + store.state.user.token,
              },
              // 跨域是否传递cookie, 默认为false
              withCredentials: false,
              // 超时时间, 默认为10s
              timeout: 5 * 1000,
              // 小于该值就插入base64格式(而不上传), 默认为0
              base64LimitKB: 5, //5kb
            },
            // insertImage: {
            //   onInsertedImage(imageNode) {
            //     console.log(this.imageList);
            //     if (this.flag) {
            //       const editor = getEditor(this.editorId);
            //       console.log(editor.getElemsByType('image'));
            //       this.flag = false;
            //     }
            //     console.log(imageNode);
            //   }
            // }
          },
        };
      },
    },
  },
  components: {
    Editor,
    Toolbar,
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor);
    },
    onChange(editor) {
      this.$emit("input", editor.children);
      this.$emit("on-change", editor.getHtml({ withFormat: false }));
    },
    init(content) {
      const editor = this.editor;
      editor.children = content;
      editor.focus();
      let editorConfig = editor.getConfig();
      let imageList = editor.getElemsByType("image");
      editorConfig.MENU_CONF["insertImage"] = {
        onInsertedImage(imageNode) {
          imageList.push(imageNode);
          let image = editor.getElemsByType("image");
          let imageSrcList = [];
          let imageConfigList = [];
          for (let i = 0; i < imageList.length; i++) {
            let imageListSplit = imageList[i].src.split("/");
            let src = imageListSplit[imageListSplit.length - 1];
            imageSrcList.push(src);
          }
          for (let j = 0; j < image.length; j++) {
            let imageSplit = image[j].src.split("/");
            let src = imageSplit[imageSplit.length - 1];
            imageConfigList.push(src);
          }
          // 将有差异的图片删除掉
          let tempSrc = [];
          for (let k = 0; k < imageSrcList.length; k++) {
            let flag = true;
            for (let h = 0; h < imageConfigList.length; h++) {
              if (imageSrcList[k] === imageConfigList[h]) {
                flag = false;
              }
            }
            if (flag) {
              tempSrc.push(imageSrcList[k]);
            }
          }
          deleteFile(JSON.stringify(tempSrc))
            .then((res) => {
              console.log(res.data);
              imageList = editor.getElemsByType("image");
            })
            .catch((err) => {
              console.log(err.toString());
            });
        },
      };
    },
  },
  data() {
    return {
      editor: null,
    };
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    // 销毁, 并移除 editor
    editor.destroy();
  },
  created() {
    this.editorId = getUuid;
  },
};
</script>

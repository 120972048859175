import axios from "@/libs/api.request";

export const getVulCatalog = (query) => {
  return axios.request({
    url: "/wiki/vul_catalog/",
    method: "get",
    params: query,
  });
};

export const addVulCatalog = (query) => {
  return axios.request({
    url: "/wiki/vul_catalog/",
    method: "post",
    data: query,
  });
};

export const getLevelCatalog = (query) => {
  return axios.request({
    url: "/wiki/vul_catalog/get_catalog/",
    method: "get",
    params: query,
  });
};

export const updateVulCatalog = (id, title) => {
  return axios.request({
    url: "/wiki/vul_catalog/" + id + "/",
    method: "put",
    data: { title: title },
  });
};

export const deleteVulCatalog = (id) => {
  return axios.request({
    url: "/wiki/vul_catalog/" + id + "/",
    method: "delete",
  });
};

export const trees = () => {
  return axios.request({
    url: "/wiki/vul_catalog/trees/",
    method: "get",
  });
};

export const tree = (query) => {
  return axios.request({
    url: "/wiki/vul_catalog/tree/",
    method: "get",
    params: query,
  });
};

export const getAllCatalog = () => {
  return axios.request({
    url: "/wiki/vul_catalog/get_all_catalog/",
    method: "get",
  });
};

export const addVulWiki = (reqData) => {
  return axios.request({
    url: "/wiki/vul_wiki/",
    method: "post",
    data: reqData,
  });
};

export const getVulWiki = (wiki_id) => {
  return axios.request({
    url: "/wiki/vul_wiki/" + wiki_id + "/",
    method: "get",
  });
};

export const getAllVulWiki = (query) => {
  return axios.request({
    url: "/wiki/vul_wiki/",
    method: "get",
    params: query,
  });
};

export const updateVulWiki = (wiki_id, reqData) => {
  return axios.request({
    url: "/wiki/vul_wiki/" + wiki_id + "/",
    method: "put",
    data: reqData,
  });
};

export const deleteVulWiki = (wiki_id) => {
  return axios.request({
    url: "/wiki/vul_wiki/" + wiki_id + "/",
    method: "delete",
  });
};

export const getVulTag = (query) => {
  return axios.request({
    url: "/wiki/vul_tag/",
    method: "get",
    params: query,
  });
};

export const addVulTag = (reqData) => {
  return axios.request({
    url: "/wiki/vul_tag/",
    method: "post",
    data: reqData,
  });
};

export const deleteVulTag = (id) => {
  return axios.request({
    url: "/wiki/vul_tag/" + id + "/",
    method: "delete",
  });
};

export const updateVulTag = (id, reqData) => {
  return axios.request({
    url: "/wiki/vul_tag/" + id + "/",
    method: "put",
    data: reqData,
  });
};

export const deleteFile = (files) => {
  return axios.request({
    url: "/daily_life/wang_delete",
    method: "get",
    params: { files: files },
  });
};

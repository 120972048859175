<template>
  <div>
    <a-drawer
      title="目录添加"
      :width="600"
      :visible="visible"
      placement="right"
      :closable="false"
      @close="onClose"
    >
      <a-spin :spinning="confirmLoading">
        <a-form-model
          :model="form"
          ref="ruleForm"
          layout="horizontal"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item ref="title" prop="title" label="标题">
            <a-input v-model="form.title"></a-input>
          </a-form-model-item>
          <a-form-model-item ref="level" prop="level" label="层级">
            <a-radio-group v-model="form.level" @change="levelChange">
              <a-radio-button value="1"> 一级目录 </a-radio-button>
              <a-radio-button value="2"> 二级目录 </a-radio-button>
              <a-radio-button value="3"> 三级目录 </a-radio-button>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="所属">
            <a-input-group compact>
              <a-select
                :disabled="one_disabled"
                style="width: 50%"
                v-model="select_one_catalog"
                @change="changeOneCatalog"
              >
                <a-select-option
                  v-for="catalog in one_catalog"
                  :value="catalog.id"
                  :key="catalog.id"
                >
                  {{ catalog.title }}
                </a-select-option>
              </a-select>
              <a-select
                :disabled="two_disabled"
                style="width: 50%"
                v-model="select_two_catalog"
              >
                <a-select-option
                  v-for="catalog in two_catalog"
                  :value="catalog.id"
                  :key="catalog.id"
                >
                  {{ catalog.title }}
                </a-select-option>
              </a-select>
            </a-input-group>
          </a-form-model-item>
          <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button type="primary" @click="submit">添加</a-button>
          </a-form-model-item>
        </a-form-model>
      </a-spin>
    </a-drawer>
  </div>
</template>

<script>
import { addVulCatalog, getLevelCatalog } from "@/api/wiki";
export default {
  data() {
    return {
      visible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      confirmLoading: false,
      form: {
        title: "",
        level: "1",
      },
      select_one_catalog: "一级目录",
      select_two_catalog: "二级目录",
      one_catalog: [],
      two_catalog: [],
      one_disabled: true,
      two_disabled: true,
      rules: {
        title: [{ required: true, message: "请输入目录标题", trigger: "blur" }],
        level: [
          { required: true, message: "请选择一个目录等级", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    init() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    levelChange() {
      let level = this.form.level;
      this.confirmLoading = true;
      if (level === "1") {
        this.one_catalog = [];
        this.two_catalog = [];
        this.one_disabled = true;
        this.two_disabled = true;
        this.select_one_catalog = "一级目录";
        this.select_two_catalog = "二级目录";
      } else if (level === "2") {
        if (this.one_catalog.length === 0) {
          getLevelCatalog({ level: "1" })
            .then((res) => {
              this.one_catalog = res.data;
            })
            .catch((err) => {
              this.$message.error(err.toString());
            });
        }
        this.two_catalog = [];
        this.one_disabled = false;
        this.two_disabled = true;
        this.select_two_catalog = "二级目录";
      } else {
        // 三级目录
        if (this.select_one_catalog !== "一级目录") {
          getLevelCatalog({ level: "2", catalog_id: this.select_one_catalog })
            .then((res) => {
              this.two_catalog = res.data;
            })
            .catch((err) => {
              this.$message.error(err.toString());
            });
        } else {
          if (this.one_catalog.length === 0) {
            getLevelCatalog({ level: "1" })
              .then((res) => {
                this.one_catalog = res.data;
              })
              .catch((err) => {
                this.$message.error(err.toString());
              });
          }
        }
        this.one_disabled = false;
        this.two_disabled = false;
      }
      this.confirmLoading = false;
    },
    changeOneCatalog() {
      if (this.form.level === "3") {
        getLevelCatalog({ level: "2", catalog_id: this.select_one_catalog })
          .then((res) => {
            this.two_catalog = res.data;
          })
          .catch((err) => {
            this.$message.error(err.toString());
          });
      }
    },
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          if (this.form.level === "1") {
            let data = {
              title: this.form.title,
              parent: "",
            };
            addVulCatalog(data)
              .then((res) => {
                if (res.status === 200) {
                  this.$message.error(res.data.message);
                } else {
                  this.$message.success("添加成功!");
                  this.$parent.$parent.fetch({
                    page: this.$parent.$parent.current,
                    page_size: this.$parent.$parent.pageSize,
                  });
                  this.$forceUpdate();
                  this.$refs["ruleForm"].resetFields();
                  this.one_catalog = [];
                  this.two_catalog = [];
                  this.one_disabled = true;
                  this.two_disabled = true;
                  this.select_one_catalog = "一级目录";
                  this.select_two_catalog = "二级目录";
                  this.visible = false;
                }
              })
              .catch((err) => {
                this.$message.error(err.toString());
              });
          } else if (this.form.level === "2") {
            if (this.select_one_catalog !== "一级目录") {
              let data = {
                title: this.form.title,
                parent: this.select_one_catalog,
              };
              addVulCatalog(data)
                .then((res) => {
                  if (res.status === 200) {
                    this.$message.error(res.data.message);
                  } else {
                    this.$message.success("添加成功!");
                    this.$refs["ruleForm"].resetFields();
                    this.$parent.$parent.fetch({
                      page: this.$parent.$parent.current,
                      page_size: this.$parent.$parent.pageSize,
                    });
                    this.$forceUpdate();
                    this.one_catalog = [];
                    this.two_catalog = [];
                    this.one_disabled = true;
                    this.two_disabled = true;
                    this.select_one_catalog = "一级目录";
                    this.select_two_catalog = "二级目录";
                    this.visible = false;
                  }
                })
                .catch((err) => {
                  this.$message.error(err.toString());
                });
            } else {
              this.$message.error("请选择一个一级目录!");
            }
          } else {
            if (this.select_one_catalog === "一级目录") {
              this.$message.error("请选择一个一级目录和二级目录!");
            } else if (this.select_two_catalog === "二级目录") {
              this.$message.error("请选择一个二级目录!");
            } else {
              let data = {
                title: this.form.title,
                parent: this.select_two_catalog,
              };
              addVulCatalog(data)
                .then((res) => {
                  if (res.status === 200) {
                    this.$message.error(res.data.message);
                  } else {
                    this.$message.success("添加成功!");
                    this.$refs["ruleForm"].resetFields();
                    this.$parent.$parent.fetch({
                      page: this.$parent.$parent.current,
                      page_size: this.$parent.$parent.pageSize,
                    });
                    this.$forceUpdate();
                    this.one_catalog = [];
                    this.two_catalog = [];
                    this.one_disabled = true;
                    this.two_disabled = true;
                    this.select_one_catalog = "一级目录";
                    this.select_two_catalog = "二级目录";
                    this.visible = false;
                  }
                })
                .catch((err) => {
                  this.$message.error(err.toString());
                });
            }
          }
          this.confirmLoading = false;
        }
      });
    },
  },
};
</script>

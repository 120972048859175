<template>
  <div>
    <a-row>
      <a-col :span="8">
        <a-card>
          <a-button type="primary" icon="plus" @click="addWiki"
            >添加wiki</a-button
          >
          <a-button
            style="margin-left: 8px"
            type="primary"
            icon="filter"
            @click="$refs['labelFilterModal'].visible = true"
            >标签过滤</a-button
          >
          <a-input-search
            style="margin-bottom: 8px; margin-top: 8px"
            placeholder="查找"
            @change="onChange"
            v-model="searchValue"
          />
          <a-tree
            :tree-data="treeData"
            showIcon
            :expanded-keys="expandedKeys"
            :auto-expand-parent="autoExpandParent"
            @expand="onExpand"
            @select="onSelect"
          >
            <a-icon
              type="appstore"
              slot="appstore"
              theme="filled"
              style="color: rgb(84, 167, 251)"
            ></a-icon>
            <a-icon
              type="file"
              slot="file"
              theme="filled"
              style="color: rgb(250, 96, 100)"
            >
            </a-icon>
          </a-tree>
        </a-card>
      </a-col>
      <a-col :span="16">
        <a-card :bordered="false" style="width: 100%">
          <a-table
            ref="table"
            style="width: 100%"
            size="small"
            :rowKey="(wiki) => wiki.wiki_id"
            :columns="columns"
            :data-source="data"
            :pagination="pagination"
            :loading="loading"
            @change="handleTableChange"
            bordered
          >
            <span slot="titl" slot-scope="text, wiki">
              <a-button type="link" @click="display(wiki)">{{ text }}</a-button>
            </span>
            <span slot="created_at" slot-scope="text">
              {{ changeTime(text) }}
            </span>
            <span slot="updated_at" slot-scope="text">
              {{ changeTime(text) }}
            </span>
            <span slot="action" slot-scope="text">
              <a-button
                type="primary"
                size="small"
                @click="editWiki(text.wiki_id)"
                >编辑</a-button
              >
              <a-button
                type="danger"
                size="small"
                @click="deleteWiki(text.wiki_id)"
                style="margin-left: 10px"
                >删除</a-button
              >
            </span>
          </a-table>
        </a-card>
      </a-col>
    </a-row>
    <label-filter-modal ref="labelFilterModal" />
  </div>
</template>

<script>
import { changeTime } from "@/libs/utils";
import { trees, tree, getAllVulWiki, deleteVulWiki } from "@/api/wiki";
import LabelFilterModal from "./modules/LabelFilterModal";
import { Modal } from "ant-design-vue";
export default {
  components: { LabelFilterModal },
  data() {
    this.changeTime = changeTime;
    return {
      selectedKeys: "",
      treeData: [],
      expandedKeys: [],
      autoExpandParent: true,
      searchValue: "",
      loading: false,
      pagination: {
        defaultPageSize: 10,
        showQuickJumper: true,
        showTotal: (total) => `共${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ["10", "50", "100", "200"],
        onShowSizeChange: (current, pageSize) => (
          (this.pageSize = pageSize), (this.current = current)
        ),
      },
      pageSize: 10,
      current: 1,
      data: [],
      columns: [
        {
          title: "标题",
          dataIndex: "title",
          ellipsis: true,
          scopedSlots: { customRender: "titl" },
          width: "26%",
        },
        {
          title: "创建时间",
          dataIndex: "created_at",
          ellipsis: true,
          scopedSlots: { customRender: "created_at" },
          width: "22%",
        },
        {
          title: "更新时间",
          dataIndex: "updated_at",
          ellipsis: true,
          scopedSlots: { customRender: "updated_at" },
          width: "22%",
        },
        {
          title: "作者",
          dataIndex: "author",
          ellipsis: true,
          width: "10%",
        },
        {
          title: "操作",
          ellipsis: true,
          scopedSlots: { customRender: "action" },
          width: "20%",
        },
      ],
      expand_key: 0,
      select_key: 0,
    };
  },
  methods: {
    display(wiki) {
      let routeData = this.$router.resolve({
        path: "/wiki/display",
        query: { wiki_id: wiki.wiki_id },
      });
      window.open(routeData.href, "_blank");
    },
    editWiki(wiki_id) {
      let routeData = this.$router.resolve({
        path: "/wiki/edit",
        query: { wiki_id: wiki_id },
      });
      window.open(routeData.href, "_blank");
    },
    deleteWiki(wiki_id) {
      Modal.confirm({
        title: "警告",
        content: "您确定要删除吗?",
        onOk: () => {
          deleteVulWiki(wiki_id)
            .then((res) => {
              if (res.status === 200) {
                this.$message.error(res.data.message);
              } else {
                this.$message.success("删除成功!");
                this.fetch({ page: this.current, page_size: this.pageSize });
              }
            })
            .catch((err) => {
              this.$message.error(err.toString());
            });
        },
        onCancel() {},
      });
    },
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.fetch({ page: this.current, page_size: this.pageSize });
    },
    onChange() {
      this.catalogFetch(this.$refs["labelFilterModal"].form.tags);
    },
    onExpand(expandedKeys, node) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
      let expanded = node.expanded;
      if (expanded === true) {
        let key = node.node.$options.propsData.eventKey;
        this.expand_key = key;
        this.select_key = 0;
        getAllVulWiki({ page: 1, page_size: 10, catalog_id: key })
          .then((res) => {
            this.data = res.data.results;
            const pagination = { ...this.pagination };
            pagination.total = parseInt(res.data.count);
            this.pagination = pagination;
          })
          .catch((err) => {
            this.$message.error(err.toString());
          });
      } else {
        this.expand_key = 0;
      }
    },
    onSelect(selectedKeys) {
      let selectedKey = selectedKeys[0];
      if (selectedKey === undefined) {
        this.select_key = 0;
        this.expand_key = 0;
        getAllVulWiki({ page: 1, page_size: 10 })
          .then((res) => {
            this.data = res.data.results;
            const pagination = { ...this.pagination };
            pagination.total = parseInt(res.data.count);
            this.pagination = pagination;
          })
          .catch((err) => {
            this.$message.error(err.toString());
          });
      } else {
        let key = parseInt(selectedKey);
        if (key === selectedKey) {
          // 整数
          this.select_key = key;
          this.expand_key = 0;
          getAllVulWiki({ page: 1, page_size: 10, catalog_id: key })
            .then((res) => {
              this.data = res.data.results;
              const pagination = { ...this.pagination };
              pagination.total = parseInt(res.data.count);
              this.pagination = pagination;
            })
            .catch((err) => {
              this.$message.error(err.toString());
            });
        } else {
          this.select_key = selectedKey;
          this.expand_key = 0;
          getAllVulWiki({ page: 1, page_size: 10, wiki_id: selectedKey })
            .then((res) => {
              this.data = res.data.results;
              const pagination = { ...this.pagination };
              pagination.total = parseInt(res.data.count);
              this.pagination = pagination;
            })
            .catch((err) => {
              this.$message.error(err.toString());
            });
        }
      }
    },
    addWiki() {
      let routerUrl = this.$router.resolve({
        path: "/wiki/edit",
      });
      window.open(routerUrl.href, "_blank");
    },
    catalogFetch(tags) {
      if (tags.length === 0 && this.searchValue === "") {
        trees()
          .then((res) => {
            this.treeData = res.data;
          })
          .catch((err) => {
            this.$message.error(err.toString());
          });
      } else {
        let data = { search: this.searchValue, tags: JSON.stringify(tags) };
        tree(data)
          .then((res) => {
            this.treeData = res.data;
            this.expandedKeys = [];
            this.handle(this.treeData);
          })
          .catch((err) => {
            this.$message.error(err.toString());
          });
      }
    },
    fetch(query = {}) {
      if (this.expand_key != 0) {
        query.catalog_id = this.expand_key;
      } else if (this.select_key != 0) {
        let key = parseInt(this.select_key);
        if (key === this.select_key) {
          // 整数
          query.catalog_id = key;
        } else {
          query.wiki_id = this.select_key;
        }
      }
      this.loading = true;
      getAllVulWiki(query)
        .then((res) => {
          this.data = res.data.results;
          const pagination = { ...this.pagination };
          pagination.total = parseInt(res.data.count);
          this.pagination = pagination;
        })
        .catch((err) => {
          this.$message.error(err.toString());
        });
      this.loading = false;
    },
    handle(datas) {
      for (let i = 0; i < datas.length; i++) {
        this.expandedKeys.push(datas[i].key);
        if (datas[i].children !== undefined) {
          this.handle(datas[i].children);
        }
      }
    },
  },
  mounted() {
    trees()
      .then((res) => {
        this.treeData = res.data;
      })
      .catch((err) => {
        this.$message.error(err.toString());
      });
    this.loading = true;
    getAllVulWiki({ page: 1, page_size: 10 })
      .then((res) => {
        this.data = res.data.results;
        const pagination = { ...this.pagination };
        pagination.total = parseInt(res.data.count);
        this.pagination = pagination;
      })
      .catch((err) => {
        this.$message.error(err.toString());
      });
    this.loading = false;
  },
};
</script>

<template>
  <div>
    <a-card>
      <a-spin :spinning="confirmLoading">
        <a-form-model :model="form" ref="ruleForm" layout="inline">
          <a-form-model-item ref="title" prop="title" label="标题">
            <a-input v-model="form.title"></a-input>
          </a-form-model-item>
          <a-form-model-item ref="catalog" label="类型">
            <a-cascader
              v-model="form.catalog"
              :options="options"
              placeholder="请选择文章类型"
            ></a-cascader>
          </a-form-model-item>
          <a-form-model-item ref="tags" label="标签">
            <a-select
              mode="multiple"
              v-model="form.tags"
              style="width: 200px"
              placeholder="请选择文章标签"
            >
              <a-select-option :key="tag.id" v-for="tag in tags">
                {{ tag.title }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item style="float: right; margin-right: 50px">
            <a-button type="primary" @click="submit" icon="edit">发布</a-button>
          </a-form-model-item>
        </a-form-model>
        <wang-editor
          style="margin-top: 10px"
          v-model="content"
          ref="wangEditor"
        />
      </a-spin>
    </a-card>
  </div>
</template>

<script>
import WangEditor from "@/components/editor/WangEditor";
import {
  getAllCatalog,
  addVulWiki,
  getVulWiki,
  getVulTag,
  updateVulWiki,
} from "@/api/wiki";
import { Modal } from "ant-design-vue";
export default {
  data() {
    return {
      wiki_id: "",
      confirmLoading: false,
      content: [],
      form: {
        title: "",
        catalog: [],
        tags: [],
      },
      options: [],
      tags: [],
    };
  },
  components: {
    WangEditor,
  },
  methods: {
    leavePage(e) {
      e.returnValue = "";
    },
    submit() {
      if (this.form.title === "") {
        this.$message.error("标题不能为空");
      } else {
        if (this.form.catalog.length !== 3) {
          this.$message.error("类型不能为空");
        } else {
          this.confirmLoading = true;
          if (this.wiki_id === "") {
            let data = {
              title: this.form.title,
              catalog: this.form.catalog[2],
              tags: this.form.tags,
              content: JSON.stringify(this.content),
            };
            addVulWiki(data)
              .then((res) => {
                if (res.status === 201) {
                  console.log(res.data);
                  console.log("test");
                  this.$message.success("发布成功!");
                  // 直接跳转不走mounted, 需要手动赋值
                  this.wiki_id = res.data.wiki_id;
                  console.log(this.wiki_id);
                  this.$router.push({
                    path: "/wiki/edit",
                    query: { wiki_id: res.data.wiki_id },
                  });
                } else {
                  this.$message.error(res.data.message);
                }
              })
              .catch((err) => {
                this.$message.error(err.toString());
              });
          } else {
            let data = {
              title: this.form.title,
              catalog: this.form.catalog[2],
              tags: this.form.tags,
              content: JSON.stringify(this.content),
            };
            updateVulWiki(this.wiki_id, data)
              .then((res) => {
                if (res.data.message === undefined) {
                  this.$message.success("发布成功!");
                } else {
                  this.$message.error(res.data.message);
                }
              })
              .catch((err) => {
                this.$message.error(err.toString());
              });
          }
          this.confirmLoading = false;
        }
      }
    },
  },
  mounted() {
    window.addEventListener("beforeunload", this.leavePage);
    getAllCatalog()
      .then((res) => {
        this.options = res.data;
      })
      .catch((err) => {
        this.$message.error(err.toString());
      });
    getVulTag()
      .then((res) => {
        this.tags = res.data.results;
      })
      .catch((err) => {
        this.$message.error(err.toString());
      });
    if ("wiki_id" in this.$route.query) {
      this.confirmLoading = true;
      this.wiki_id = this.$route.query.wiki_id;
      getVulWiki(this.wiki_id)
        .then((res) => {
          let data = res.data;
          this.form.title = data.title;
          this.form.catalog = data.catalog;
          this.form.tags = data.tags;
          this.wiki_id = data.wiki_id;
          this.$refs["wangEditor"].init(JSON.parse(data.content));
        })
        .catch((err) => {
          this.$message.error(err.toString());
        });
      this.confirmLoading = false;
    }
  },
  beforeRouteLeave(to, from, next) {
    Modal.confirm({
      title: "警告",
      content: "您确定要离开此页面?",
      onOk: () => {
        next();
      },
      onCancel() {},
    });
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.leavePage);
  },
};
</script>

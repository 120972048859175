<template>
  <div>
    <a-modal
      :visible="visible"
      title="标签过滤"
      :width="400"
      @cancel="handleCancel"
      @ok="submit"
      :confirmLoading="confirmLoading"
    >
      <a-form-model
        :model="form"
        ref="ruleForm"
        layout="horizontal"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="tags" prop="tags" label="标签">
          <a-select
            mode="multiple"
            v-model="form.tags"
            placeholder="请选择文章标签"
          >
            <a-select-option :key="tag.id" v-for="tag in tags">
              {{ tag.title }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { getVulTag } from "@/api/wiki";
export default {
  data() {
    return {
      confirmLoading: false,
      visible: false,
      tags: [],
      form: {
        tags: [],
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
    };
  },
  methods: {
    handleCancel() {
      this.visible = false;
    },
    submit() {
      this.$parent.catalogFetch(this.form.tags);
      this.visible = false;
    },
  },
  mounted() {
    getVulTag()
      .then((res) => {
        this.tags = res.data.results;
      })
      .catch((err) => {
        this.$message.error(err.toString());
      });
  },
};
</script>
